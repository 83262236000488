import type { Schemas } from "#shopware";
import { getSmallestThumbnailUrl } from "@shopware/helpers";

export const getThumbnailUrl = (media: Schemas["Media"]) => {
	const thumbnail = getSmallestThumbnailUrl(media);

	if (!thumbnail) {
		const url = media.url || "https://placehold.co/600x400";
		console.warn("No thumbnail found for media", media.alt, `Using fallback: ${url}`);
		return url;
	}

	return thumbnail;
};
